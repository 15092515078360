.passwordRow {
  position: relative;
}

.passwordRow button {
  position: absolute;
  bottom: 0rem;
  right: 1rem;
  border-radius: 50%;
  background: transparent;
  border-color: transparent;
  cursor: pointer;
}

.passwordRow svg {
  font-size: 1.25rem;
}
