:root{
  --font__family:"Poppins", sans-serif;
  --main__bg__color:#F6FAFD;
  --primary__base__color:#42C5EC;
  --secondary__base__color:#673D94;
  --warning__base_color:#FB8A00;
  --error__base__color:#F44336;
  --info__base_color:#B8B8B8;
  --success__base__color:#43A048;
/* text color */
  --text__primary__color:#2A2A2A;
  --text__secondary__color:#414141;
  --text__disabled__coloe:#B8B8B8;
  --text__tertiary__color:#5A5A5A;
/* text Style */
  --text__regular:400;
  --text__medium:500;
  --text__semibold:600;
  --text__bold:700;
  --text__bolder:800;
  
  --text__34:34px;
  --text__28:28px;
  --text__24:24px;
  --text__22:22px;
  --text__20:20px;
  --text__18:18px;
  --text__16:16px;
  --text__14:14px;
  --text__12:12px;
  --text__10:10px;

  --text__41lh:41px;
  --text__34lh:34px;
  --text__30lh:30px;
  --text__28lh:28px;
  --text__26lh:26px;
  --text__27lh:27px;
  --text__24lh:24px;
  --text__22lh:22px;
  --text__23lh:23px;
  --text__20lh:20px;
  --text__18lh:18px;
  --text__15lh:15px;
  --text__16lh:16px;


}
.login_body{padding-top:85px}
.input-error {
	border: 1px solid red !important;
}

.error {
  margin-top: 5px;
	color: red;
	font-size: 10px;
}
