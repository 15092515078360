

.searchCoupons {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  margin-bottom: 1rem;
  position: relative;
}

.searchCoupons > div {
  display: flex;
  width: 100%;
}

.searchCoupons svg {
  font-size: 1.5rem;
  color: var(--primary-500);
}

.couponSuggestion {
  position: absolute;
  left: 1rem;
  top: 2.5rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--grey-100);
  background: var(--white);
  box-shadow: var(--shadow-3);
  border-radius: var(--borderRadius);
}

.couponSuggestion > button {
  background: var(--white);
  color: var(--black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;
  position: relative;
  border-radius: 0;
  border-color: transparent;
  cursor: pointer;
  transition: var(--transition);
  padding: 0.35rem;
}

.couponSuggestion > button:hover {
  background: var(--primary-100);
}

.couponSuggestion > button:disabled {
  background: var(--grey-100);
}

.couponSuggestion > .btnDisableMobile {
  background: var(--grey-100);
  cursor: not-allowed;
}

.couponSuggestion > .btnDisableMobile:hover {
  background: var(--grey-100);
}

.tooltip {
  visibility: hidden;
  position: absolute;
  top: -1.5rem;
  left: 0;
  background: var(--white);
  padding: 0.5rem;
  z-index: 3;
  border: 2px solid var(--primary-700);
  font-size: 0.5rem;
  border-radius: var(--borderRadius);
}

.closeBtn {
  color: var(--red-dark);
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .tooltip {
    font-size: 0.75rem;
    left: 25%;
    top: 10%;
  }

  .couponSuggestion > button:disabled:hover .tooltip {
    visibility: visible;
  }

  article.checkout {
    min-width: 25rem;
  }
  .couponSuggestion > button:hover:not(button:disabled) {
    background: var(--primary-100);
  }
}

@media screen and (min-width: 1024px) {
  article.checkout {
    min-width: 30rem;
  }
}

.couponSuggestion div {
  border: 2px dashed var(--primary-500);
  padding: 0.25rem;
}

.couponApplied {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-500);
  font-weight: 600;
  cursor: default;
}

.couponText {
  margin: 0;
}
