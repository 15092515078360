/***** Splash Screen  Start *********/

.splash__screen{
  min-height:100dvh;
  line-height: 0; font-size:0px;
  background-color:var(--primary__base__color);
  & .motion_bg{
    position: absolute;
    height:100dvh;
    width:100dvw;
    z-index: 1;
    font-size: 0;
    line-height: 0;
    &::before{
      content: "";
      width: 100VW;
      height: 100VW;
      /* background-color: #42c5ec; */
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      background: rgb(66,197,236);
      background: radial-gradient(circle, rgba(66, 197, 236, 1) 30%, rgb(204 204 204 / 0%) 90%);

    }
    
    & img{
        width:100%;
        height: -webkit-fill-available;
        opacity: 0.2;
        /* animation: rotate360 100s linear infinite; */
    }
    
  }
  & .splash_body{
    position: relative;
    height:100dvh;
    z-index: 2;
    width: 100%;

    & .logo_splash{
      position: relative;
       top:50%; 
       left:50%;
       transform:translate(-50%, -50%) scale(0.1);
       text-align: center;
       animation: logoani 0.6s linear forwards;
    }
    & .subtext_splash{
      position: absolute;
      top: calc(14% + 76px);
      width: 100%;
      text-align: center;
      opacity:0;
      animation: subtextani 0.3s linear forwards;
      animation-delay: 0.7s;
    }
    & .bottal_splash{
      position: absolute;
      bottom: 3%;
      transform: translateX(-100%);
      width: 100%;
      text-align: center;
      opacity:0;
      animation:bottalani 0.3s linear forwards;
      animation-delay:0.6s;
    }
   
  }
}
@keyframes logoani{
100%{
  top:12%;
  left:0%;
  transform:scale(1);
}
}
@keyframes bottalani{
90% {
  opacity:0.5;
}
100%{
  opacity:1;
  transform: translateX(0%);
}
}
@keyframes subtextani{
100%{
  opacity:1;
}
}

/***** Splash Screen  END *********/