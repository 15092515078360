.hero {
  min-height: calc(100vh - 5rem);
  display: flex;
  align-items: center;
  gap: 0 1rem;
  color: var(--primary-800);
}

.content h1 {
  font-size: 2.25rem;
  font-weight: 600;
}

.content p {
  font-size: 1rem;
  color: var(--grey-500);
  letter-spacing: var(--letterSpacing);
}

.btnHero {
  font-size: 1.25rem;
  padding: 0.5rem 1.25rem;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .content h1 {
    font-size: 2.25rem;
  }

  .content p {
    font-size: 0.95rem;
  }
  .imageContainer {
    display: block;
    width: 100%;
    margin-left: -1rem;
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1024px) {
  .content h1 {
    font-size: 3rem;
  }

  .content p {
    font-size: 1.25rem;
  }
  .imageContainer {
    margin-left: 0;
  }
}
