.listContainer > p {
  font-size: 1rem;
  font-weight: 600;

  text-align: center;
  letter-spacing: var(--letterSpacing);
}

.listContainer {
  padding: 1rem;
}
