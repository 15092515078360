.searchBarContainer {
  width: 40%;
  max-width: 400px;
  margin: 0 auto;

  position: relative;
  display: flex;
}

.searchBtn {
  border-color: transparent;
  background: var(--grey-50);
  margin-right: 1.1rem;
  cursor: pointer;
  color: var(--primary-600);
  transition: var(--transition);
}

.searchBtn:active {
  background: var(--primary-200);
}

.suggestions {
  position: absolute;
  top: 2.5rem;
  left: 0;
  width: 100%;
  min-height: fit-content;
  max-height: 15rem;
  overflow-y: auto;
  background: var(--white);
  border: 2px solid var(--grey-100);
  box-shadow: var(--shadow-4);
  border-radius: var(--borderRadius);
}

.suggestions p {
  margin: 0;
}

.suggestions button {
  width: 100%;
  background: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  text-transform: capitalize;
  border-top: 1px solid var(--grey-100);
  color: var(--black);
  font-size: 0.85rem;
  cursor: pointer;
  border-color: transparent;
  letter-spacing: var(--letterSpacing);
  transition: var(--transition);
}

.suggestions button:hover {
  background: var(--grey-100);
}

.suggestions button p {
  color: var(--grey-500);
}

.suggestions button span {
  color: var(--primary-600);
}

.suggestions button:hover p {
  color: var(--grey-800);
}

.suggestions button:hover span {
  font-weight: 600;
}

.textPlease {
  letter-spacing: var(--letterSpacing);
  font-size: 1.1rem;
  color: var(--primary-800);
}

@media screen and (max-width: 768px) {
  .searchBarContainer {
    order: 3;
    margin-top: -1.5rem;
    width: 100%;
  }
}
