/******** global Eelement style ***********/
.component_style{
  padding: 0 20px;
  margin-bottom: 30px;
  &.slider_component{
    padding:0 0 0 20px;
  }
}
.component_title{
  color:rgba(90, 90, 90, 1);
  font-size: var(--text__14);
  line-height: var(--text__27lh);
  font-weight: var(--text__semibold);
  margin-bottom: 15px;
}

.slider_container{
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -webkit-box-align: center;
  align-items: center;
  & .slider_item{
    scroll-snap-align: start start;
    flex-shrink: 0;
  }
}

.foodtype{
  width:12px;
  height:11px;
  border:1px solid transparent;
  border-radius:2px;
  position: relative;
  display: inline-block;
  background-color:#fffdff;
  &::before{
    width:6px;
    height:6px;
    content: "";
    background-color: transparent;
    position:absolute;
    top:50%; left:50%; transform: translate(-50%, -50%);
    border-radius:100%;
  }
  &.veg{
    border-color: var(--success__base__color);
    &::before{
      background-color:var(--success__base__color);
    }
  }
  &.nonveg{
    border-color: var(--error__base__color);
    &::before{
      background-color:var(--error__base__color);
    }
  }
}
.titl_link{ display: flex; justify-content: space-between; column-gap: 10px;
  & .link_type{ font-size:var(--text__12); font-weight: var(--text__bold); line-height:var(--text__27lh) ;}}
.btn_styl{
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
  align-items: center;
  border:1px solid var(--primary__base__color);
  color: var(--primary__base__color);
  padding:2px 9px;
  border-radius:5px;
  font-size:var(--text__10);
  line-height: var(--text__15lh);
}
.link_type{
  color:var(--primary__base__color);
  text-decoration: underline;
}
/******************************************** Home Screen *************************************************/
/***** common header ********/
.bpabh_maincontainer{ max-width:440px; margin:0 auto; padding-bottom: 95px;
  & .header{
    min-height:262px;
    position: relative;
    isolation: isolate;
    background-color: var(--primary__base__color);
   &::after{
    content:"";
    width:100%;
    height:100%;
    position: absolute;
    top:0; left:0; opacity:0.2;
    /* background: url(/images/topheader-bg.png) no-repeat 0 0; background-size: cover; */
    isolation: isolate;
    z-index: -1;
   }
  }
 & .header_hero{
   display: flex; align-items: center; justify-content: space-between; padding: 20px;
   & .glob_search{
     & .search_cont{
      position: relative;
      & .search_app{
        background-color:var(--main__bg__color);
        border: 1px solid rgba(66, 197, 236, 1);
        border-radius:8px;
        font-size:10px;
        line-height: 15px;
        min-width:222px;
        height:26px;
        padding:0 30px 0 10px;
        color: rgba(103, 61, 148, 0.4);
        font-weight: var(--text__medium);
        &::placeholder{
          color: rgba(103, 61, 148, 0.6);
        }
      }
      & .seachicon{
        position: absolute;
        top:5px; right:10px;
        cursor: pointer;
      }
     }
   }
 }

 & .header_sub{
    background-color:rgb(48 173 210 / 33%);
    height: 100%;
    padding:20px 20px 15px;
    & .subhead_cont{
       & .twcolnm{
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        & .usr_loc{
          background-color:#fffdff;
          border-radius:30px;
          font-weight: var(--text__bold);
          color: var(--warning__base_color);
          font-size: var(--text__10);
          display: flex;
          align-items: center;
          padding:3px 5px;
          column-gap: 3px;
        }
        & .stre_status{
          &.openstus{
             background-color:var(--success__base__color);
             color:#fff;
             font-size: var(--text__10);
             font-weight: var(--text__medium);
             display: flex;
             align-items: center;
             padding: 3px 5px;
             border-radius:20px;
             column-gap: 3px;
             &::before{
              content: "";
              width:8px;
              height: 8px;
              border:2px solid #fff;
              border-radius:10px;
             }
          }
        }
       }
       & .main_title{
         font-size: var(--text__22);
         line-height: var(--text__28lh);
         color:var(--secondary__base__color);
         font-weight: var(--text__bold);
         text-shadow: 1px 1px 0px #fff;
         margin-bottom: 25px;

       }
      & .intal_advet{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap:10px;
        & .advet_item{
          background-color:#fffdff;
          border-radius:60px;
          display: flex;
          align-items: center;
          padding:10px 5px;
          column-gap: 4px;
          font-size:8px;
          line-height: 12px;
          color:#000;
          font-weight: var(--text__medium);
        }
      }
    }
 }

 & .transt_status{
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding:10px 20px;
   column-gap: 20px;
   & input[type=radio]{
     opacity: 0;
     width:0px;
   &:checked + label{
    font-size: var(--text__16);
     &::before{
      background-color:var(--main__bg__color);
      border:5px solid var(--secondary__base__color);
     }
   }
   }
   & label{
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: var(--text__14);
    font-weight: var(--text__medium);
    color: var(--secondary__base__color);
    width:100%;
    &::before{
     content:"";
     background-color:var(--secondary__base__color);
     width:15px;
     height:15px;
     border:5px solid var(--main__bg__color);
     display: block;
     border-radius: 100%;
   }
  }
  & .delvry_action{
    background-color: #fffdff;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 30px;
    flex: 1 0 0;
  }
 }

 & .component_bgslider{
    & .slider_wrapper{
      & .slider_container{
         column-gap: 20px;
        & .slider_item{
           width:308px;
           & a{
            display: block;
            line-height: 0;
           }
           & img{
             border-radius:32px;
           }
         }
       }
    }
 }

 & .component_sellerslider{
     & .slider_container{
        & .slider_item{
           width:267px;
           padding:9px 0 9px 9px;
          & .seller_card{
            background-color:#fffdff;
            border-radius:40px;
            padding:10px;
            box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.1);
           & .items_poster{
               border-radius:30px 30px 0 0;
               overflow: hidden;
               line-height: 0px;
               margin-bottom: 10px;
           }
           & .badge_tag{
             display: flex;
             align-items: center;
             column-gap: 4px;
             padding-bottom: 2px;
             & .badge{
              color:var(--primary__base__color);
              font-size:8px; line-height: 11px;
              background-color: color-mix(in srgb, var(--primary__base__color) 15%, transparent);
              padding:0 4px;
              border-radius: 20px;
              
             }
           }
           & .item_details{
              display: flex;
              justify-content: space-between;
              column-gap:10px;
              padding-bottom: 7px;
              & .item_name{
                font-size:var(--text__16);
                line-height:var(--text__20lh);
                font-weight: var(--text__bold);
                color:var(--text__primary__color);
              }
             & .inr_price{
              font-size:var(--text__16);
              line-height:var(--text__20lh);
              font-weight: var(--text__medium);
              color:var(--text__primary__color);
             }
           }
           & .item_discr{
             display: flex;
             justify-content:space-between;
             column-gap:5px;
             padding-bottom: 10px;
             & .item_srtinfo{
              font-size:8px; line-height:12px;
              color:var(--info__base_color);
             }
             & .item_crtaction_btn{
              display: flex;
              align-items: flex-end;
             }
           }


          }
        }
       
     }

 }

 & .gridmenu_list{
   & .grid_layout{
    display: grid;
    grid-template-columns: repeat(3, 1fr); grid-gap:10px; grid-gap: 30px 10px;
    & .grid_item{
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
      background-color:#fff;
      border-radius:10px;
      & .item_poster{ border-radius:10px; overflow: hidden; line-height: 0;}
      & .item_title{ text-align: center; color:var(--text__primary__color); font-size:10px; line-height: var(--text__23lh); padding:3px 3px 5px; font-weight: var(--text__medium);}
    }

   }

 }

}


/**** bottom navigation Start ********/
.sticky_nav{ position: fixed; width:100%; background-color: #fff; border-radius: 30px 30px  0 0; bottom:0; padding:10px 0px 15px; display: grid; grid-template-columns: repeat(5, 1fr);
 & .nav_item{
  text-align: center;
  position: relative;
  & .nav_icon{
    height:37px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    & .numbitems{
      position: absolute;
    top: 0;
    background: rgba(251, 138, 0, 1);
    font-size: 10px;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    color: #fff;
    line-height: 14px;
    font-weight: 500;
    margin-left: 15px;
    font-style: normal;
    }
  }
  & .nav_text{
    font-size: var(--text__12);
    line-height: var(--text__18lh);
    font-weight: var(--text__medium);
    color: var(--text__secondary__color);
  }
  &.activenav{
      svg{
        height: 37px;
        width: 37px;
        path{
          fill:var(--secondary__base__color);
          fill-opacity:1;
          
        }
      }
      & .nav_text{
        color: var(--secondary__base__color);
      }
  }
 }
}
/**** bottom navigation END ********/
