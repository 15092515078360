.productStyle {
  background: var(--white);
  border: 1px solid var(--grey-100);
  border-radius: var(--borderRadius);
  width: 80%;
  position: relative;
  transition: var(--transition);
  box-shadow: var(--shadow-2);
  cursor: default;
}

.productStyle:hover {
  box-shadow: var(--shadow-4);
}

.disabledProduct::before {
  content: 'Out of Stock';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--overlay-bcg);
  z-index: 2;
  cursor: not-allowed;
  color: var(--white);
  letter-spacing: var(--letterSpacing);
  font-size: 1.25rem;
  display: grid;
  place-items: center;
  border-radius: var(--borderRadius);
  font-weight: 600;
}

.imgContainer {
  height: 10rem;
  width: 100%;
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  overflow: hidden;
  border-bottom: 2px solid var(--grey-100);
  cursor: pointer;
  opacity: 0.9;
}

.imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: var(--transition);
}

.heartContainer {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  display: flex;
  padding: 0.25rem;
  border-radius: 50%;
  cursor: pointer;
  border-color: transparent;
  background: var(--white);
  transition: var(--transition);
}

.heartContainer svg {
  font-size: 1.25rem;
}

.heartContainer.coloredHeart {
  display: flex;
  background: var(--white);
  color: var(--red-dark);
}

.cardInfo {
  padding: 0.5rem 1rem;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardHeader p {
  margin: 0;
  font-size: 0.95rem;
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rating {
  padding: 0.1rem 0.4rem;
  font-size: 0.75rem;
  background: var(--primary-600);
  border-radius: var(--borderRadius);
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 0 0.3rem;
}

.cardMain {
  margin-top: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 0.5rem;
}

.cardMain span {
  font-size: 0.85rem;
}

.cardMain span:nth-child(2n-1) {
  font-weight: bold;
}

.cardMain span:nth-child(2) {
  color: var(--grey-500);
  text-decoration: line-through;
}

.discount {
  color: var(--green-medium);
}

.colorsContainer,
.colorsContainerDefault {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  display: flex;
  gap: 0 0.5rem;
}

.colorsContainer span,
.colorsContainerDefault span {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.colorsContainerDefault span {
  cursor: default;
}

.colorsContainer svg {
  color: var(--white);
  font-weight: 600;
}

.footer {
  width: 100%;

  display: flex;
  justify-content: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.footer .cardBtn {
  display: block;
  color: var(--white);
  padding: 0.5rem;
  width: 100%;
}

.footer .cardBtn.goToCartBtn {
  background: var(--primary-900);
}

/* skeleton card css */

.skeletonCard {
  height: 15rem;
}

.skeletonImg {
  height: 10rem;
  width: 100%;
  background: var(--grey-100);
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--grey-100);
  }
  100% {
    background-color: var(--grey-200);
  }
}

@media screen and (min-width: 768px) {
  .heartContainer:hover {
    background: var(--red-light);
  }

  .productStyle {
    width: 100%;
  }
  .imgContainer {
    height: 15rem;
  }

  .imgContainer img {
    scale: 1.1;
  }

  .imgContainer img:hover {
    scale: 0.9;
  }

  .cardHeader p {
    font-size: 1.25rem;
    letter-spacing: var(--letterSpacing);
  }

  .rating {
    letter-spacing: var(--letterSpacing);
    font-size: 1rem;
  }

  .cardMain {
    margin-top: 0.35rem;
    gap: 0 1rem;
  }

  .cardMain span {
    font-size: 1rem;
  }

  .footer {
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .colorsContainer {
    margin-top: 1rem;
  }

  .colorsContainer span {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
  }

  .disabledProduct::before {
    font-size: 1.5rem;
  }

  /* skeleton card css */
  .skeletonCard {
    height: 24rem;
  }

  .skeletonImg {
    height: 15rem;
  }
}
