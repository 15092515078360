.cartItem {
  height: fit-content;
  border-radius: var(--borderRadius);
  transition: var(--transition);
  box-shadow: var(--shadow-3);
  background: var(--white);
  padding: 2rem 1rem;
  width: fit-content;
}

@media screen and (min-width: 768px) {
  .cartItem {
    margin-right: 0;
    padding: 2rem;
    padding-bottom: 1.25rem;
  }
}

.cartItem:hover {
  box-shadow: var(--shadow-4);
}

.cartItemMain {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.imgContainer {
  height: 6rem;
  width: 6rem;
  overflow: hidden;
  border: 2px solid var(--primary-200);
  border-radius: var(--borderRadius);
}

.imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cartItemMain h3 {
  font-size: 0.95rem;
  font-weight: 600;
  color: var(--primary-700);
  margin-bottom: 0.5rem;
}

.cardMain {
  composes: cardMain from '../ProductCard/ProductCard.module.css';
}

.discount {
  color: var(--green-medium);
}

.counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 5rem;
  color: var(--grey-500);
  margin-top: 0.25rem;
}

.counter button {
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 50%;
  border-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: var(--black);
  transition: var(--transition);
  margin-bottom: 0.25rem;
}

.counter button span {
  font-size: 2rem;
}

.counter button:last-child span {
  margin-bottom: 0.25rem;
}

.counter div {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.cartBtnContainer {
  display: flex;
  justify-content: flex-start;
  font-size: 0.85rem;
}

.colorCircle {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 0.25rem 0;
}

@media screen and (min-width: 768px) {
  .cartItemMain h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .cartItemMain {
    margin-bottom: 1.25rem;
  }

  .cardMain {
    margin-bottom: 0.5rem;
  }

  .cardMain span {
    font-size: 1.15rem;
  }

  .imgContainer {
    height: 10rem;
    width: 10rem;
  }

  .counter {
    margin-top: 0.5rem;
  }

  .counter button {
    width: 1.35rem;
    height: 1.35rem;
  }

  .counter button span {
    font-size: 2.5rem;
  }
  .counter div {
    font-size: 1.25rem;
  }

  .colorCircle {
    width: 1.35rem;
    height: 1.35rem;
    border-radius: 50%;
    margin: 0.35rem 0;
  }

  .cartBtnContainer {
    justify-content: center;
    font-size: 1.1rem;
  }
}
