*,
*::after,
*::before {
	margin: 0;
	padding: 0px;
	box-sizing: border-box;
}
::focus-within,
::focus {
	outline: none;
}
body {
	font-size: 100%;
	line-height: 1;
	font-family: var(--font__family);
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	min-height: 100vh;
	background-color: var(--main__bg__color);
}
img {
	max-width: 100%;
}
input {
	border: 0;
	font-family: inherit;
}
.btngroup {
	display: flex;
	column-gap: 15px;
}
.topad_banner {
	background-color: #9747ff;
	color: #fff;
	padding: 12px 7.5%;
	position: absolute;
	top: 2%;
	font-size: 14px;
	line-height: 24px;
	display: flex;
	align-items: center;
	column-gap: 10px;
}
/******** global Eelement style ***********/
.widget_rlgap {
	padding: 0 20px;
	margin-bottom: 20px;
}
.component_style {
	padding: 0 20px;
	margin-bottom: 30px;
	&.slider_component {
		padding: 0 0 0 20px;
	}
}
.component_title {
	color: rgba(90, 90, 90, 1);
	font-size: var(--text__14);
	line-height: var(--text__27lh);
	font-weight: var(--text__semibold);
	margin-bottom: 15px;
}
.componenet_subtitle_bord {
	font-size: var(--text__12);
	line-height: var(--text__20lh);
	font-weight: var(--text__bold);
	color: rgba(90, 90, 90, 1);
	border-bottom: 1px solid rgba(232, 232, 232, 1);
	margin-bottom: 10px;
}

.slider_container {
	display: flex;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scrollbar-width: none;
	-webkit-box-align: center;
	align-items: center;
	& .slider_item {
		scroll-snap-align: start start;
		flex-shrink: 0;
	}
}

.foodtype {
	width: 12px;
	height: 11px;
	border: 1px solid transparent;
	border-radius: 2px;
	position: relative;
	display: inline-block;
	background-color: #fffdff;
	&::before {
		width: 6px;
		height: 6px;
		content: "";
		background-color: transparent;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 100%;
	}
	&.veg {
		border-color: var(--success__base__color);
		&::before {
			background-color: var(--success__base__color);
		}
	}
	&.nonveg {
		border-color: var(--error__base__color);
		&::before {
			background-color: var(--error__base__color);
		}
	}
	&.egg {
		border-color: var(--warning__base_color);
		&::before {
			background-color: var(--warning__base_color);
		}
	}
}
.badge {
	color: var(--primary__base__color);
	font-size: 8px;
	line-height: 11px;
	background-color: color-mix(
		in srgb,
		var(--primary__base__color) 15%,
		transparent
	);
	padding: 0 4px;
	border-radius: 20px;
}
.item_srtinfo {
	font-size: 8px;
	line-height: 12px;
	color: var(--info__base_color);
}

.titl_link {
	display: flex;
	justify-content: space-between;
	column-gap: 10px;
	& .link_type {
		font-size: var(--text__12);
		font-weight: var(--text__bold);
		line-height: var(--text__27lh);
		cursor: pointer;
	}
}
.btn_styl {
	display: flex;
	justify-content: space-between;
	column-gap: 10px;
	align-items: center;
	border: 1px solid var(--primary__base__color);
	color: var(--primary__base__color);
	padding: 2px 9px;
	border-radius: 5px;
	font-size: var(--text__10);
	line-height: var(--text__15lh);
}
.link_type {
	color: var(--primary__base__color);
	text-decoration: underline;
	cursor: pointer;
}
.sticky_cart {
	display: flex;
	place-content: center;
	position: fixed;
	width: calc(100% - 13px);
	bottom: calc(40px + 80px);
	align-items: center;
	filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.3));
	& .cart_items {
		background-color: var(--primary__base__color);
		height: 36px;
		flex: 1 0 0;
		display: flex;
		align-items: center;
		padding: 10px;
		position: relative;
		right: -13px;
		border-radius: 8px 0 0 8px;
		font-size: 14px;
		color: #fff;
	}
	& .cart_icon {
		background: var(--primary__base__color);
		border-color: var(--primary__base__color);
		width: 84px;
		height: 45px;
		position: relative;
		display: inline-block;
		&::after {
			content: "";
			width: 0;
			position: absolute;
			bottom: -25px;
			border-top: 25px solid;
			border-color: inherit;
			border-left: 42px solid transparent;
			border-right: 42px solid transparent;
		}
		&::before {
			content: " ";
			width: 0;
			height: 0;
			border-bottom: 25px solid;
			border-color: inherit;
			border-left: 42px solid transparent;
			border-right: 42px solid transparent;
			position: absolute;
			top: -25px;
		}

		& .cart_wrap {
			/* width: 84px;
      height: 84px; */
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			& .numbitems {
				position: absolute;
				top: -2px;
				right: 23px;
				background-color: var(--warning__base_color);
				color: #fff;
				font-size: 12px;
				border-radius: 100%;
				width: 15px;
				height: 15px;
				display: grid;
				place-content: center;
				font-weight: 600;
			}
		}
	}
}

.lght_subtitle {
	font-size: var(--text__10);
	line-height: var(--text__20lh);
	padding-bottom: 10px;
}
.primery_btn {
	background-color: var(--secondary__base__color);
	color: #fff;
	display: flex;
	align-items: center;
	column-gap: 3px;
	padding: 0 10px;
	&.addbtn {
		border: 0px;
		border-radius: 5px;
		font-size: var(--text__10);
		line-height: var(--text__23lh);
		font-weight: var(--text__semibold);
		margin-bottom: 10px;
	}
}
.big_btn_scondry {
	background-color: var(--secondary__base__color);
	color: #fff;
	text-align: center;
	border-radius: 8px;
	font-size: var(--text__16);
	line-height: var(--text__23lh);
	font-weight: var(--text__medium);
	padding: 16px 10px 15px;
}

/***** Splash Screen  Start *********/
.splash__screen {
	min-height: 100dvh;
	line-height: 0;
	font-size: 0px;
	background-color: var(--primary__base__color);
	& .motion_bg {
		position: absolute;
		height: 100dvh;
		width: 100dvw;
		z-index: 1;
		font-size: 0;
		line-height: 0;
		&::before {
			content: "";
			width: 100vw;
			height: 100vw;
			/* background-color: #42c5ec; */
			top: 50%;
			left: 50%;
			position: absolute;
			transform: translate(-50%, -50%);
			background: rgb(66, 197, 236);
			background: radial-gradient(
				circle,
				rgba(66, 197, 236, 1) 30%,
				rgb(204 204 204 / 0%) 90%
			);
		}

		& img {
			width: 100%;
			height: -webkit-fill-available;
			opacity: 0.2;
			/* animation: rotate360 100s linear infinite; */
		}
	}
	& .splash_body {
		position: relative;
		height: 100dvh;
		z-index: 2;
		width: 100%;

		& .logo_splash {
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0.1);
			text-align: center;
			animation: logoani 0.6s linear forwards;
		}
		& .subtext_splash {
			position: absolute;
			top: calc(14% + 76px);
			width: 100%;
			text-align: center;
			opacity: 0;
			animation: subtextani 0.3s linear forwards;
			animation-delay: 0.7s;
		}
		& .bottal_splash {
			position: absolute;
			bottom: 3%;
			transform: translateX(-100%);
			width: 100%;
			text-align: center;
			opacity: 0;
			animation: bottalani 0.3s linear forwards;
			animation-delay: 0.6s;
		}
	}
}
@keyframes logoani {
	100% {
		top: 12%;
		left: 0%;
		transform: scale(1);
	}
}
@keyframes bottalani {
	90% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}
@keyframes subtextani {
	100% {
		opacity: 1;
	}
}

/***** Splash Screen  END *********/

/****** login Screen *******/

.login__screen {
	min-height: 100dvh;
	background-color: var(--primary__base__color);
	& .motion_bg {
		position: absolute;
		height: 100dvh;
		width: 100dvw;
		z-index: 1;
		font-size: 0;
		line-height: 0;
		&::before {
			content: "";
			width: 100vw;
			height: 100vw;
			/* background-color: #42c5ec; */
			top: 50%;
			left: 50%;
			position: absolute;
			transform: translate(-50%, -50%);
			background: rgb(66, 197, 236);
			background: radial-gradient(
				circle,
				rgba(66, 197, 236, 1) 30%,
				rgb(204 204 204 / 0%) 90%
			);
			z-index: 1;
		}
		& img {
			width: 100%;
			height: -webkit-fill-available;
			opacity: 0.2;
		}
	}
	& .login_body {
		position: relative;
		height: 100dvh;
		z-index: 2;
		width: 100%;
		/* display: grid; */
		place-content: center;

		& .logo_top {
			position: relative;
			transform: scale(1);
			text-align: center;
			margin-bottom: 10px;
		}
		& .subtext_top {
			width: 100%;
			text-align: center;
			margin-bottom: 40px;
		}
		& .signup_body {
			background: #ffffff33;
			background-color: rgba(255, 255, 255, 0.2);
			padding: 20px 10px 10px;
			color: #fff;
			border-radius: 33px;
			max-width: 85%;
			margin: 0 auto 35px;

			& .heading_top {
				font-size: var(--text__16);
				line-height: var(--text__30lh);
				text-align: center;
				font-weight: var(--text__medium);
				margin-bottom: 10px;
			}
			& .subheading_top {
				font-size: var(--text__12);
				line-height: var(--text__15lh);
				text-align: center;
				font-weight: var(--text__medium);
				margin-bottom: 20px;
			}
			& .signup {
				& .form_field {
					margin-bottom: 10px;
					& button {
						width: 100%;
						background-color: transparent;
						border: 0px;
						padding: 15px 10px;
						text-align: center;
						font-size: var(--text__16);
						line-height: var(--text__23lh);
						font-family: inherit;
						font-weight: var(--text__medium);
						&.submit_but {
							background-color: var(--secondary__base__color);
							color: #fff;
							border-radius: 8px;
							margin-bottom: 20px;
						}
						&.secondry_but {
							color: var(--secondary__base__color);
						}
					}
					&:last-child {
						margin-bottom: 0px;
					}
					&.opt__fields {
						display: grid;
						grid-template-columns: repeat(6, 1fr);
						column-gap: 7px;
						& .optnum {
							font-size: 20px;
							font-weight: 800;
							text-align: center;
						}
					}
					& .opt__action {
						font-size: var(--text__16);
						line-height: var(--text__23lh);
						text-align: center;
						padding-top: 10px;
						padding-bottom: 10px;
						font-weight: var(--text__medium);

						& span {
							color: var(--secondary__base__color);
						}
					}
				}
			}
			& input {
				width: 100%;
				outline: none;
				font-size: var(--text__16);
				font-weight: var(--text__medium);
				line-height: var(--text__23lh);
				background-color: #f6fafd;
				border-radius: 8px;
				border: 1px solid #b8b8b8;
				padding: 15px 15px;
				font-family: inherit;
				&::placeholder {
					color: #d0d0d0;
				}
			}
		}
	}
	& .term__section {
		display: flex;
		align-items: center;
		max-width: 85%;
		margin: 0 auto;
		& label {
			position: relative;
			&:before {
				content: "";
				-webkit-appearance: none;
				background-color: transparent;
				border: 2px solid var(--secondary__base__color);
				padding: 8px;
				display: inline-block;
				position: relative;
				vertical-align: middle;
				cursor: pointer;
				margin-right: 5px;
				border-radius: 16px;
			}
			&:has(input:checked):after {
				content: "";
				display: block;
				position: absolute;
				top: 4px;
				left: 7px;
				width: 6px;
				height: 10px;
				border: solid var(--secondary__base__color);
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);
			}
		}
		& #term {
			display: none;
		}
		& span {
			font-size: var(--text__12);
			font-weight: var(--text__18lh);
			color: #fff;
			& a {
				text-decoration: none;
				color: var(--secondary__base__color);
			}
		}
	}
}

/******************************************** Home Screen *************************************************/
/***** common header ********/
.bpabh_maincontainer {
	max-width: 440px;
	margin: 0 auto;
	padding-bottom: 95px;
	& .header {
		min-height: 262px;
		position: relative;
		isolation: isolate;
		background-color: var(--primary__base__color);
		&::after {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0.2;
			/* background: url(/images/topheader-bg.png) no-repeat 0 0; background-size: cover; */
			isolation: isolate;
			z-index: -1;
		}
	}
	& .header_hero {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px;
		column-gap: 10px;
		& .sm_logo {
			flex-basis: 97px;
			flex-shrink: 0;
		}
		& .glob_search {
			flex: 1 0 0;
			& .search_cont {
				position: relative;
				display: flex;
				justify-content: right;
				& .search_app {
					background-color: var(--main__bg__color);
					border: 1px solid rgba(66, 197, 236, 1);
					border-radius: 8px;
					font-size: 10px;
					line-height: 15px;
					max-width: 222px;
					height: 26px;
					padding: 0 30px 0 10px;
					color: rgba(103, 61, 148, 0.4);
					font-weight: var(--text__medium);
					width: 100%;
					&::placeholder {
						color: rgba(103, 61, 148, 0.6);
					}
				}
				& .seachicon {
					position: absolute;
					top: 5px;
					right: 10px;
					cursor: pointer;
				}
			}
		}
	}

	& .header_sub {
		background-color: rgb(48 173 210 / 33%);
		height: 100%;
		padding: 20px 20px 15px;
		& .subhead_cont {
			& .twcolnm {
				display: flex;
				justify-content: space-between;
				margin-bottom: 8px;
				& .usr_loc {
					background-color: #fffdff;
					border-radius: 30px;
					font-weight: var(--text__bold);
					color: var(--warning__base_color);
					font-size: var(--text__10);
					display: flex;
					align-items: center;
					padding: 3px 5px;
					column-gap: 3px;
				}
				& .stre_status {
					&.openstus {
						background-color: var(--success__base__color);
						color: #fff;
						font-size: var(--text__10);
						font-weight: var(--text__medium);
						display: flex;
						align-items: center;
						padding: 3px 5px;
						border-radius: 20px;
						column-gap: 3px;
						&::before {
							content: "";
							width: 8px;
							height: 8px;
							border: 2px solid #fff;
							border-radius: 10px;
						}
					}
				}
			}
			& .main_title {
				font-size: var(--text__22);
				line-height: var(--text__28lh);
				color: var(--secondary__base__color);
				font-weight: var(--text__bold);
				text-shadow: 1px 1px 0px #fff;
				margin-bottom: 25px;
			}
			& .intal_advet {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				grid-gap: 10px;
				& .advet_item {
					background-color: #fffdff;
					border-radius: 60px;
					display: flex;
					align-items: center;
					padding: 10px 5px;
					column-gap: 4px;
					font-size: 8px;
					line-height: 12px;
					color: #000;
					font-weight: var(--text__medium);
				}
			}
		}
	}

	& .transt_status {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 20px;
		column-gap: 20px;
		& input[type="radio"] {
			opacity: 0;
			width: 0px;
			&:checked + label {
				font-size: var(--text__16);
				&::before {
					background-color: var(--main__bg__color);
					border: 5px solid var(--secondary__base__color);
				}
			}
		}
		& label {
			display: flex;
			align-items: center;
			column-gap: 10px;
			font-size: var(--text__14);
			font-weight: var(--text__medium);
			color: var(--secondary__base__color);
			width: 100%;
			&::before {
				content: "";
				background-color: var(--secondary__base__color);
				width: 15px;
				height: 15px;
				border: 5px solid var(--main__bg__color);
				display: block;
				border-radius: 100%;
			}
		}
		& .delvry_action {
			background-color: #fffdff;
			display: flex;
			align-items: center;
			padding: 10px 15px;
			border-radius: 30px;
			flex: 1 0 0;
		}
	}

	& .component_bgslider {
		& .slider_wrapper {
			& .slider_container {
				column-gap: 20px;
				& .slider_item {
					width: 308px;
					& a {
						display: block;
						line-height: 0;
					}
					& img {
						border-radius: 32px;
					}
				}
			}
		}
	}

	& .component_sellerslider {
		& .slider_container {
			& .slider_item {
				width: 267px;
				padding: 9px 0 9px 9px;
				& .seller_card {
					background-color: #fffdff;
					border-radius: 40px;
					padding: 10px;
					box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.1);
					& .items_poster {
						border-radius: 30px 30px 0 0;
						overflow: hidden;
						line-height: 0px;
						margin-bottom: 10px;
					}
					& .badge_tag {
						display: flex;
						align-items: center;
						column-gap: 4px;
						padding-bottom: 2px;
					}
					& .item_details {
						display: flex;
						justify-content: space-between;
						column-gap: 10px;
						padding-bottom: 7px;
						& .item_name {
							font-size: var(--text__16);
							line-height: var(--text__20lh);
							font-weight: var(--text__bold);
							color: var(--text__primary__color);
						}
						& .inr_price {
							font-size: var(--text__16);
							line-height: var(--text__20lh);
							font-weight: var(--text__medium);
							color: var(--text__primary__color);
						}
					}
					& .item_discr {
						display: flex;
						justify-content: space-between;
						column-gap: 5px;
						padding-bottom: 10px;
						& .item_crtaction_btn {
							display: flex;
							align-items: flex-end;
						}
					}
				}
			}
		}
	}

	& .gridmenu_list {
		& .grid_layout {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 10px;
			grid-gap: 30px 10px;
			& .grid_item {
				box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
				background-color: #fff;
				border-radius: 10px;
				& .item_poster {
					border-radius: 10px;
					overflow: hidden;
					line-height: 0;
				}
				& .item_title {
					text-align: center;
					color: var(--text__primary__color);
					font-size: 10px;
					line-height: var(--text__23lh);
					padding: 3px 3px 5px;
					font-weight: var(--text__medium);
				}
			}
		}
	}
	& .accordion_list {
		list-style: none;
		& li {
			position: relative;
			display: block;
			width: 100%;
			height: auto;
			border-bottom: 1px solid rgba(232, 232, 232, 1);
			cursor: pointer;
			margin-bottom: 7px;
			padding-bottom: 5px;
			& h3 {
				font-size: var(--text__14);
				line-height: var(--text__27lh);
				font-weight: var(--text__semibold);
				color: rgba(90, 90, 90, 1);
				&::after {
					content: "";
					width: 12px;
					height: 7px;
					background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNC4xNTgiIGhlaWdodD0iOC40OTIiIHZpZXdCb3g9IjAgMCAxNC4xNTggOC40OTIiPgogIDxwYXRoIGlkPSJjaGV2cm9uLXVwIiBkPSJNMTcuNjU2LDE2LjIzN2ExLjE4LDEuMTgsMCwwLDAsMS40MTQtMS40MTRMMTIsNy43NTEsNC45MjksMTQuODIyYTEuMzQ2LDEuMzQ2LDAsMCwwLDEuNDE0LDEuNDE0TDEyLDEwLjU4WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQuOTI4IC03Ljc1MSkiLz4KPC9zdmc+Cg==);
					background-repeat: no-repeat;
					position: absolute;
					right: 5px;
					background-position: center center;
					top: 12px;
					transform: rotate(180deg);
					cursor: pointer;
					background-size: 100%;
				}
			}
			&.active {
				& h3 {
					&::after {
						transform: rotate(360deg);
					}
				}
			}
		}
	}

	& .products_items {
		padding-top: 10px;
		padding-bottom: 10px;
		& .foodtype_list {
			display: flex;
			align-items: center;
			column-gap: 30px;
			margin-bottom: 15px;
			& .list_item {
				font-size: var(--text__12);
				display: flex;
				align-items: center;
				column-gap: 10px;
				font-weight: var(--text__medium);
				
				& .foodtype {
					width: 24px;
					height: 22px;
					&::before {
						width: 12px;
						height: 12px;
					}
				}
			}
		}
	}

	& .vcard_item {
		display: grid;
		grid-template-columns: 130px 1fr;
		grid-column-gap: 15px;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
		border-radius: 8px;
		margin-bottom: 10px;
		& .items_poster {
			position: relative;
			line-height: 0px;
			& .foodtype {
				position: absolute;
				top: 10px;
				left: 10px;
			}
		}
		& .item_detail {
			padding: 5px 10px 5px 0;
			& .badge_tag {
				margin-bottom: 15px;
			}
			& .item_name {
				font-size: var(--text__16);
				line-height: var(--text__20lh);
				font-weight: var(--text__semibold);
			}
			& .item_discr {
				margin-bottom: 4px;
			}
			& .pric_crt {
				display: flex;
				justify-content: space-between;
				align-items: center;
				column-gap: 10px;
				& .inr_price {
					font-size: var(--text__16);
					line-height: var(--text__27lh);
					font-weight: var(--text__medium);
				}
			}
		}
	}

	& .usr_address_wapper {
		& .primery_btn {
			background-color: var(--secondary__base__color);
			color: #fff;
			display: flex;
			align-items: center;
			column-gap: 3px;
			padding: 0 10px;
			&.addbtn {
				border: 0px;
				border-radius: 5px;
				font-size: var(--text__10);
				line-height: var(--text__23lh);
				font-weight: var(--text__semibold);
				margin-bottom: 10px;
			}
		}
		& .usr_address_sets {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 10px;
		}
		& .address_usr {
			border: 1px solid rgba(66, 197, 236, 1);
			border-radius: 8px;
			cursor: pointer;
			padding: 2px 8px 5px;
			& .address_usr_head {
				padding-bottom: 5px;
				display: flex;
				justify-content: space-between;
				& .user_name {
					font-size: var(--text__10);
					line-height: var(--text__15lh);
					font-weight: var(--text__semibold);
				}
				& .address_type {
					font-size: 8px;
					line-height: var(--text__15lh);
					color: rgba(150, 150, 150, 1);
				}
			}
			& .address_usr_full {
				font-size: var(--text__10);
				line-height: var(--text__15lh);
				font-weight: var(--text__medium);
			}
		}
		& .address_usr.selected {
			background: color-mix(
				in srgb,
				var(--primary__base__color) 15%,
				transparent
			);
		}
	}

	& .cart_items_list {
		& .cart_item {
			display: grid;
			grid-template-columns: 1fr 70px;
			column-gap: 10px;
			border-bottom: 1px solid rgba(232, 232, 232, 1);
			padding-bottom: 10px;
			margin-bottom: 10px;
			& .item_lhsnfo {
				display: flex;
				align-items: flex-start;
				column-gap: 25px;
				& .item_poster {
					flex-basis: 73px;
					flex-shrink: 0;
					line-height: 0;
				}
				& .item_name {
					font-size: var(--text__12);
					font-weight: var(--text__semibold);
					line-height: var(--text__20lh);
					padding-bottom: 10px;
				}
				& .item_qntprc {
					font-size: var(--text__12);
					line-height: var(--text__24lh);
				}
			}
			& .itemprc_details {
				& .fnlprc {
					font-size: var(--text__14);
					line-height: var(--text__27lh);
					font-weight: var(--text__semibold);
					margin-bottom: 10px;
				}
				& .qntbtn {
					border: 1px solid rgba(99, 206, 238, 1);
					text-align: center;
					font-size: 10px;
					background-color: color-mix(
						in srgb,
						var(--primary__base__color) 15%,
						transparent
					);
					padding: 4px 0;
					border-radius: 6px;
					display: grid;
					grid-template-columns: 20px 1fr 20px;
					align-items: center;
				}
			}
		}
	}
	& .frequent_order_vcard {
		& .vcard_list {
			border-bottom: 1px solid rgba(232, 232, 232, 1);
			padding-bottom: 0px;
		}
	}
	& .other_order_instruc {
		& .instruc_inpur {
			background: rgba(247, 247, 247, 1);
			border: 1px solid rgba(184, 184, 184, 1);
			width: 100%;
			border-radius: 8px;
			min-height: 50px;
			padding: 5px 10px;
			font-size: var(--text__10);
			line-height: var(--text__20lh);
			font-family: inherit;
			box-shadow: 0 1px 1px #c8c6c6;
		}
	}

	& .coupon_btn {
		cursor: pointer;
		& .primery_btn_coupon {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: var(--primary__base__color);
			box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
			border-radius: 8px;
			padding: 10px 15px;
		}
		& .btn_wrap {
			display: flex;
			align-items: center;
			column-gap: 10px;
			font-size: var(--text__12);
			line-height: var(--text__20lh);
			font-weight: var(--text__semibold);
		}
		& .coupon_arrow {
			display: grid;
			place-items: center;
		}
	}

	& .billing_table {
		background: color-mix(
			in srgb,
			var(--primary__base__color) 15%,
			transparent
		);
		padding: 15px 0 10px;
		margin-bottom: 20px;
		& .billing_tbl_wrapper {
			padding: 0 20px;
			& .tb_head {
				font-size: var(--text__12);
				line-height: var(--text__20lh);
				font-weight: var(--text__bold);
				border-bottom: 1px solid rgba(232, 232, 232, 1);
			}
			& .tb_rows {
				display: grid;
				grid-template-columns: 1fr 1fr;
				font-size: var(--text__12);
				line-height: var(--text__20lh);
				padding: 10px 0;
				font-weight: var(--text__medium);
				& .tb_colum:nth-child(2) {
					text-align: center;
				}
				& .sub_rows_disc {
					display: block;
					color: rgba(184, 184, 184, 1);
					font-size: var(--text__10);
					line-height: var(--text__20lh);
					padding-bottom: 10px;
				}
				& .sub_txtdetails {
					display: block;
					font-size: 8px;
					line-height: var(--text__24lh);
				}
			}
			& .tb_billtotl {
				border-top: 1px solid rgba(232, 232, 232, 1);
				border-bottom: 1px solid rgba(232, 232, 232, 1);
				font-weight: var(--text__bold);
			}
		}
	}
	& .payment_cart {
		padding-top: 50px;
		& .sticky_cart {
			position: relative;
			bottom: 0;
			& .cart_items {
				background-color: var(--warning__base_color);
				font-weight: var(--text__semibold);
				justify-content: space-around;
			}
			& .cart_icon {
				background: var(--warning__base_color);
				border-color: var(--warning__base_color);
			}
		}
	}

	/******* Second header type style ******/
	& .blank_header {
		padding-top: 30px;
		margin-bottom: 20px;
		& .logo_blank {
			text-align: center;
			padding-bottom: 20px;
		}
		& .page_backbtn {
			display: flex;
			align-items: center;
			column-gap: 10px;
			font-size: var(--text__14);
			line-height: var(--text__27lh);
			font-weight: var(--text__semibold);
			color: rgba(90, 90, 90, 1);
			& .backicon {
				display: flex;
			}
		}
		&.logolft {
			& .logo_blank {
				text-align: left;
				padding-bottom: 0;
			}
		}
	}

	& .delvery_map {
		position: relative;
		& .addres_full {
			display: grid;
			grid-template-columns: 22px 1fr;
			& .addres_details {
				padding-left: 15px;
				& .addres_name {
					font-size: var(--text__14);
					line-height: 1.2;
					font-weight: var(--text__semibold);
				}
				& .addres_location {
					font-size: var(--text__10);
					line-height: var(--text__20lh);
					font-weight: 400;
					color: rgba(184, 184, 184, 1);
				}
			}
		}
		& .esttime_section {
			min-height: 80px;
			display: grid;
			justify-content: flex-start;
			align-items: center;
			& .esttime_capsule {
				background: color-mix(
					in srgb,
					var(--primary__base__color) 15%,
					transparent
				);
				border: 1px solid rgba(66, 197, 236, 1);
				font-size: var(--text__12);
				line-height: var(--text__24lh);
				color: rgba(66, 197, 236, 1);
				padding: 3px 15px;
				border-radius: 10px;
				margin-left: 25px;
				font-weight: var(--text__semibold);
			}
		}
		&::after {
			content: "";
			width: 1px;
			height: calc(100% - 68px);
			border: 1px dashed #000;
			display: block;
			position: absolute;
			top: 47%;
			transform: translateY(-50%);
			left: 31px;
		}
	}

	& .payment_option {
		& .payment_options {
			display: flex;
			column-gap: 15px;
			align-items: center;
			margin-bottom: 30px;
		}
	}

	& .payment_process {
		& .loader {
			&.bigloader {
				width: 200px;
				height: 200px;
				margin-inline: auto;
			}
		}
		& .loder_anim {
			margin-inline: auto;
			width: 100%;
			text-align: center;
			min-height: calc(100dvh - 100px);
			display: grid;
			place-content: center;
			& .loader_info {
				font-size: var(--text__14);
				line-height: var(--text__27lh);
				font-weight: var(--text__semibold);
				padding-top: 30px;
			}
		}
	}

	& .payment_popup {
		position: absolute;
		z-index: 1;
		bottom: -100%;
		width: 100%;
		& .popup_container {
			padding: 75px 20px 20px;
			background-color: #fff;
			border-radius: 24px 24px 0 0;
			text-align: center;
			& .checkicon {
				position: absolute;
				width: 150px;
				height: 150px;
				background-color: #fff;
				top: -75px;
				border-radius: 100%;
				left: 50%;
				transform: translateX(-50%);
				display: grid;
				place-content: center;
				& .checked_symb {
					width: 110px;
					height: 110px;
					background-color: var(--success__base__color);
					border-radius: 100%;
					display: grid;
					place-content: center;
				}
			}

			& .mesg_popup {
				font-size: var(--text__18);
				line-height: var(--text__27lh);
				font-weight: var(--text__bold);
			}
			& .popup_ord {
				font-size: var(--text__10);
				line-height: var(--text__20lh);
				margin-bottom: 10px;
			}
			& .other_mesg {
				font-size: var(--text__12);
				line-height: var(--text__24lh);
				background-color: color-mix(
					in srgb,
					var(--primary__base__color) 15%,
					transparent
				);
				color: var(--secondary__base__color);
				padding: 5px 10px;
				border-radius: 30px;
				font-weight: var(--text__semibold);
				display: inline-block;
				margin-bottom: 15px;
			}
			& .bigbtn {
				background-color: var(--secondary__base__color);
				color: #fff;
				padding: 15px 10px;
				text-align: center;
				font-size: var(--text__16);
				line-height: var(--text__24lh);
				font-weight: var(--text__semibold);
				border-radius: 8px;
			}
		}
	}

	&.payment_popupout {
		padding-bottom: 0px;
		& .payment_popup {
			bottom: 0px;
			transition: bottom 0.4s ease-in-out;
		}
		&:before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			background-color: rgba(0, 0, 0, 0.4);
		}
	}

	& .ordertype_btn {
		& .orderbtn {
			border: 1px solid rgba(99, 206, 238, 1);
			background-color: color-mix(
				in srgb,
				var(--primary__base__color) 15%,
				transparent
			);
			border-radius: 10px;
			display: inline-block;
			font-size: var(--text__12);
			line-height: var(--text__24lh);
			padding: 0 12px;
			color: var(--primary__base__color);
			font-weight: var(--text__medium);
		}
	}
	/******* User Profile ******/
	& .title_bckbtn {
		display: flex;
		align-items: center;
		justify-content: space-between;
		& .component_title {
			margin-bottom: 0px;
		}
		& .page_backbtn {
			display: flex;
			align-items: center;
			column-gap: 2px;
			font-size: var(--text__14);
			font-weight: var(--text__semibold);
			color: rgba(90, 90, 90, 1);
		}
	}
	& .user_address_list {
		& .usr_address_wapper {
			margin-bottom: 20px;
			& .address_usr {
				& .address_usr_full {
					max-width: 60%;
				}
			}
		}
	}
	& .order_histry_list {
		& .order_details_wrap {
			border: 1px solid rgba(66, 197, 236, 1);
			background: rgba(224, 245, 252, 1);
			border-radius: 8px;
			font-size: var(--text__10);
			padding: 7px 0 10px;
			margin-bottom: 10px;
			box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
			& .row {
				display: grid;
				grid-template-columns: 1fr 100px;
				padding: 3px 10px;
				& .ord_details {
					display: block;
					padding-bottom: 7px;
				}
				& .ord_address {
					font-size: 8px;
					line-height: 15px;
				}
			}

			& .row_btns {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				border-top: 1px dashed rgba(66, 197, 236, 1);
				margin-top: 5px;
				& .colm {
					text-align: center;
					margin: 15px 3px 5px;
					font-weight: var(--text__medium);
					border-left: 1px solid rgba(66, 197, 236, 1);
					line-height: 15px;
					&:nth-child(1) {
						border: 0px;
					}
				}
			}
			& .msg_info {
				font-size: 8px;
				line-height: 15px;
				color: rgba(184, 184, 184, 1);
			}
		}
	}
	& .add_address_form {
		& .address_type {
			display: flex;
			column-gap: 20px;
			justify-content: space-between;
			& .type {
				border: 1px solid rgba(66, 197, 236, 1);
				font-size: var(--text__14);
				line-height: var(--text__27lh);
				font-weight: var(--text__medium);
				padding: 2px 20px;
				border-radius: 8px;
				color: #5a5a5a;
				cursor: pointer;
			}
		}
		& .address_map {
			& .delivry_address {
				display: flex;
				align-items: center;
				column-gap: 10px;
				padding-bottom: 10px;
				& .address_details {
					font-size: 14px;
					line-height: 22px;
					font-weight: var(--text__semibold);
				}
			}
		}
	}
	& .user_profile {
		& .user_details {
			text-align: center;
			margin-bottom: 20px;
			& .user_pic {
				width: 80px;
				height: 80px;
				border-radius: 100%;
				overflow: hidden;
				margin: 0 auto 20px;
			}
			& .user_name {
				font-size: 18px;
				line-height: 25px;
				font-weight: 500;
				color: #414141;
			}
			& .user_mobile {
				font-size: 12px;
				line-height: 18px;
				color: #414141;
			}
		}
		& .userprofile_edit {
			& .field {
				margin-bottom: 20px;
				&:nth-of-type(2) {
					margin-bottom: 50px;
				}
			}
		}
	}
	& .page_content {
		& p {
			font-size: 14px;
			line-height: 22px;
			color: #5a5a5a;
			padding-bottom: 30px;
			&:last-of-type {
				padding-bottom: 5px;
			}
		}
		& .payment_scaner {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 10px;
			& .scanner_item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-end;
				& .text_items {
					font-size: 14px;
					line-height: 24px;
					text-align: center;
					width: 100%;
					padding-top: 10px;
				}
			}
		}
		& .social_links {
			margin-bottom: 10px;
			padding-top: 10px;
			& ul {
				list-style: none;
				display: grid;
				grid-template-columns: 1fr 1fr;
				place-content: center;
				& li {
					display: flex;
					align-items: center;
					font-size: 14px;
					line-height: 19px;
					column-gap: 7px;
					padding-bottom: 25px;
					& .share_iocn {
						flex-shrink: 0;
						line-height: 0;
					}
					&:last-of-type {
						grid-column: span 2;
						justify-content: center;
					}
				}
			}
		}
	}
}

/**** bottom navigation Start ********/
.sticky_nav{ 
  position: fixed; width:100%; background-color: #fff; border-radius: 30px 30px  0 0; bottom:0; padding:10px 0px 15px; display: grid; grid-template-columns: repeat(5, 1fr);
  max-width: 480px;
  left: 50%;
  transform: translateX(-50%);
 & .nav_item{
  text-align: center;
  position: relative;
  & .nav_icon{
    height:37px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    & .numbitems{
      position: absolute;
    top: 0;
    background: rgba(251, 138, 0, 1);
    font-size: 10px;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    color: #fff;
    line-height: 14px;
    font-weight: 500;
    margin-left: 15px;
    font-style: normal;
    }
    & svg{
      transition: height 0.3s ease-in-out;
    }
  }
  & .nav_text{
    font-size: var(--text__12);
    line-height: var(--text__18lh);
    font-weight: var(--text__medium);
    color: var(--text__secondary__color);
  }
  &.activenav{
      svg{
        height: 37px;
        width: 37px;
        path{
          fill:var(--secondary__base__color);
          fill-opacity:1;
          
        }
      }
      & .nav_text{
        color: var(--secondary__base__color);
      }
  }
 }
}
/**** bottom navigation END ********/

.inner_deliver_status {
	& .delivery_status {
		margin: 0 0;
	}
}
.delivery_status {
	background-color: color-mix(
		in srgb,
		var(--primary__base__color) 15%,
		transparent
	);
	padding: 10px 10px 15px;
	border-radius: 8px;
	box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.12);
	margin: 10px 20px 20px;
	position: relative;

	& .order-tracking {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		position: relative;
		&:before {
			content: "";
			width: 90%;
			position: absolute;
			right: 15px;
			height: 30px;
			background-color: #fff;
			border-radius: 20px;
		}
		.step {
			text-align: center;
			position: relative;
			flex-basis: 75px;
			max-width: 75px;
			flex-shrink: 0;
			& p {
				margin: 0;
				font-size: 8px;
				line-height: 24px;
				color: #333;
				font-weight: var(--text__semibold);
			}
			& .time_date {
				font-size: 6px;
				line-height: 10px;
				color: rgb(150 150 150);
				position: relative;
				top: -5px;
			}
			.circle {
				width: 10px;
				height: 10px;
				background-color: var(--secondary__base__color);
				border-radius: 50%;
				margin: 10px auto;
				text-indent: -999999px;
				border: 4px solid var(--primary__base__color);
				z-index: 2;
				position: relative;
			}
			&:not(:first-child) {
				&::after {
					content: "";
					position: absolute;
					top: 13px;
					left: -50%;
					width: 100%;
					height: 5px;
					background-color: transparent;
					z-index: 1;
				}
			}
			&.completed {
				& .circle {
					border: 2px solid var(--primary__base__color);
				}
				&::after {
					background-color: var(--secondary__base__color);
				}
			}
		}
	}
	& .hm_status_header {
		display: flex;
		justify-content: space-between;
		padding-bottom: 15px;
		font-size: var(--text__12);
		line-height: var(--text__24lh);
	}
	& .status_hm_actn {
		display: flex;
		justify-content: space-between;
		position: absolute;
		width: 100%;
		bottom: -20px;
		left: 0;
		& .hm_actn {
			flex-grow: 1;
			text-align: center;
			color: #fff;
			background-color: var(--secondary__base__color);
			padding: 5px;
			border-radius: 0 0 8px 8px;
			font-size: var(--text__10);
			line-height: var(--text__20lh);
		}
	}
}

/******** Navigation Main *********/

.navopen {
	overflow: hidden;
	&:before {
		content: "";
		width: 100%;
		height: 100dvh;
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}
	& .sticky_nav {
		z-index: 1;
	}
	.side_navigation {
		transform: translate(0, 0);
	}
}
.side_navigation {
	position: absolute;
	background-color: #fff;
	top: 0;
	z-index: 1;
	height: 100dvh;
	border-radius: 0 80px 80px 0;
	padding: 30px 0;
	width: 250px;
	box-shadow: 4px 2px 12px 0px rgba(0, 0, 0, 0.12);
	transform: translate(-100%, 0);
	transition: transform 0.3s ease-in-out;
	& .navigation_wrap {
		position: relative;
		height: 100%;
	}
	& .closebnt {
		cursor: pointer;
		padding: 0 20px;
		display: flex;
		align-items: center;
		column-gap: 10px;
		font-size: var(--text__16);
		font-weight: normal;
		margin-bottom: 15px;
	}
	& .user_profile {
		padding: 0 20px;
		& .user_pic {
			width: 74px;
			height: 74px;
			border-radius: 100%;
			overflow: hidden;
			margin-bottom: 15px;
		}
		& .user_info {
			& .user_name {
				font-size: var(--text__18);
				line-height: var(--text__24lh);
				font-weight: var(--text__medium);
			}
			& .user_contact {
				font-size: var(--text__12);
				line-height: var(--text__18lh);
				font-weight: var(--text__medium);
				margin-bottom: 15px;
			}
			& .edit_profile_btn {
				background-color: var(--secondary__base__color);
				max-width: 160px;
				font-size: var(--text__12);
				line-height: var(--text__23lh);
				color: #fff;
				text-align: center;
				border-radius: 8px;
				padding: 8px;
				font-weight: var(--text__medium);
				margin-bottom: 30px;
			}
		}
	}
	& nav {
		ul {
			li {
				display: flex;
				align-items: center;
				column-gap: 10px;
				padding: 12px 20px;
				border-bottom: 1px solid rgba(232, 232, 232, 1);
				font-size: var(--text__12);
				line-height: var(--text__16lh);
				position: relative;
				& .msginfo {
					position: absolute;
					right: 10px;
					bottom: 4px;
					color: var(--success__base__color);
					font-size: 10px;
					border-radius: 20px;
					padding: 0 8px;
					background-color: color-mix(
						in srgb,
						var(--success__base__color) 10%,
						transparent
					);
					line-height: var(--text__20lh);
				}
			}
		}
	}
	& .fass_certif {
		position: absolute;
		bottom: 20px;
		font-size: var(--text__12);
		line-height: var(--text__16lh);
		width: 100%;
		padding-left: 20px;
	}
}

/********* Form Default Styling ******/
.form_containers {
	& label {
		display: block;
		font-size: 12px;
		line-height: 24px;
		font-weight: 500;
		padding-bottom: 8px;
		color: #5a5a5a;
	}
	& input {
		border: 1px solid rgba(66, 197, 236, 1);
		background: rgba(224, 245, 252, 1);
		min-height: 43px;
		border-radius: 8px;
		width: 100%;
		padding: 0 10px;
		font-size: 13px;
		&::placeholder {
			color: rgba(103, 61, 148, 0.5);
			font-size: 9px;
			font-weight: 500;
		}
	}
	& .form_wapper {
		& .field {
			margin-bottom: 15px;
		}
		& .field_group {
			display: flex;
			column-gap: 20px;
			justify-content: space-between;
			margin-bottom: 20px;
			& .field {
				margin-bottom: 0px;
			}
		}
	}
	& .location_input {
		position: relative;
		& input {
			min-height: 27px;
			padding-right: 65px;
		}
		& button {
			position: absolute;
			right: 0;
			border: 0px;
			background-color: transparent;
			height: 20px;
			&.searchicon {
				right: 40px;
				top: 50%;
				transform: translateY(-50%);
			}
			&.picklocation {
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}
