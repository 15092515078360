.footer {
  padding: 2rem;
  background: var(--black);
}

.linksContainer {
  color: var(--white);
  margin: 0 auto;

  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  gap: 1rem;
}

.linksContainer a {
  font-size: 1.25rem;
  color: var(--white);
  transition: var(--transition);
}

.footer .copyrightDiv {
  color: var(--white);
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 0 0.5rem;
}

.jethaDiv {
  position: relative;
}

.nameBtn {
  padding: 0;
  color: var(--primary-300);
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, -1rem);
  width: 10rem;
  letter-spacing: var(--letterSpacing);
  color: var(--black);
  background: #eed202;
  font-weight: bold;
  font-size: 0.65rem;
  padding: 0.25rem 0.75rem;
  border-radius: var(--borderRadius);
  visibility: hidden;
  transition: var(--transition);
  display: none;
}

.tooltip::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #eed202 transparent transparent transparent;
}

.jethaDiv:hover .tooltip {
  visibility: visible;
  transform: translate(-50%, -0.25rem);
}

@media screen and (min-width: 768px) {
  .tooltip {
    display: block;
  }

  .footer .copyrightDiv {
    letter-spacing: var(--letterSpacing);
    font-size: 1.15rem;
  }

  .linksContainer {
    gap: 0 2rem;
  }

  .linksContainer a {
    font-size: 1.75rem;
  }

  .linksContainer a:hover {
    color: var(--primary-400);
  }
}
