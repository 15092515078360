*,*::after,*::before{ margin:0; padding:0px; box-sizing: border-box;}
body{ font-size:100%; line-height:1; font-family: var(--font__family);text-rendering: optimizeLegibility;-webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;min-height: 100vh; background-color: var(--main__bg__color);}
img{ max-width:100%;} a{ text-decoration: none; color: inherit;}
input{ border: 0; font-family: inherit;}
.btngroup{ display: flex; column-gap: 15px;}
.topad_banner{
  background-color: #9747FF;
  color: #fff;
  padding: 12px 7.5%;
  /* position: absolute; */
  top: 2%;
  font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.login__screen{
  min-height:100dvh;
  background-color:var(--primary__base__color);
  & .motion_bg{
    position: absolute;
    height:100dvh;
    width:100dvw;
    z-index: 1;
    font-size: 0;
    line-height: 0;
    &::before{
      content: "";
      width: 100VW;
      height: 100VW;
      /* background-color: #42c5ec; */
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      background: rgb(66,197,236);
      background: radial-gradient(circle, rgba(66, 197, 236, 1) 30%, rgb(204 204 204 / 0%) 90%);
      z-index: 1;
    }
    & img{
        width:100%;
        height: -webkit-fill-available;
        opacity: 0.2;
    }
    
  }
  & .login_body{
    position: relative;
    height:100dvh;
    z-index: 2;
    width: 100%;
    /* display: grid; */
    place-content: center;

    & .logo_top{
      position: relative;
       transform: scale(1);
       text-align: center;
       margin-bottom: 10px;
    }
    & .subtext_top{
      width: 100%;
      text-align: center;
      margin-bottom: 40px;
    }
   & .signup_body{
    background: #FFFFFF33;
    background-color: rgba(255, 255, 255, 0.2);
    padding:20px 10px 10px;
    color: #fff;
    border-radius:33px;
    max-width: 85%;
    margin: 0 auto 35px;
    

     & .heading_top{
       font-size: var(--text__16);
       line-height: var(--text__30lh);
       text-align: center;
       font-weight: var(--text__medium);
       margin-bottom: 10px;
     }
     & .subheading_top{
      font-size: var(--text__12);
      line-height: var(--text__15lh);
      text-align: center;
      font-weight: var(--text__medium);
      margin-bottom: 20px;
     }
     & .signup{
         & .form_field{
          margin-bottom: 10px;
          & button{
           width:100%;
           background-color: transparent;
           border:0px;
           padding:15px 10px;
           text-align: center;
           font-size: var(--text__16);
           line-height: var(--text__23lh);
           font-family: inherit;
           font-weight: var(--text__medium);
           &.submit_but{
            background-color: var(--secondary__base__color);
            color:#fff;
            border-radius: 8px;
            margin-bottom: 20px;
           }
           &.secondry_but{
             color:var(--secondary__base__color);
             a {
               color: var(--secondary__base__color);
             }
           }
          }
          &:last-child{
            margin-bottom: 0px;
          }
          &.opt__fields{
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            column-gap:7px;
            & .optnum{
              font-size:20px;
              font-weight:800;
              text-align: center;
            }
          }
         & .opt__action{
          font-size: var(--text__16);
          line-height: var(--text__23lh);
          text-align: center;
          padding-top: 10px;
          padding-bottom: 10px;
          font-weight: var(--text__medium);

          & span{
            color:var(--secondary__base__color);
          }
         }
         }
     }
     & input{
      width: 100%;
      outline: none;
      font-size: var(--text__16);
      font-weight: var(--text__medium);
      line-height: var(--text__23lh);
      background-color:#F6FAFD;
      border-radius:8px;
      border:1px solid #b8b8b8;
      padding: 15px 15px;
      font-family: inherit;
      &::placeholder{
        color:#D0D0D0;
      }
     }
   }
  }
  & .term__section{
    display: flex;
    align-items: center;
    max-width:85%;
    margin: 0 auto;
    & label{
      position: relative;
      &:before{
        content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid var(--secondary__base__color);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius:16px;
      }
    &:has(input:checked):after{
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: 7px;
      width: 6px;
      height: 10px;
      border: solid var(--secondary__base__color);
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    }
    & #term{
      display: none;
    }
    & span{
      font-size:var(--text__12);
      font-weight: var(--text__18lh);
      color:#fff;
      & a{
        text-decoration: none;
        color:var(--secondary__base__color);

      }
    }
  }
}