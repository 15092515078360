.productListSection {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.errorContainer {
  margin-left: auto;
  margin-right: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: var(--overlay-bcg);
  pointer-events: all;
}

.productListSection .listHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  margin: 1rem 0;
  gap: 1rem;
}

.listHeader p {
  margin: 0;
}

.productListSection > p {
  margin-top: 2rem;
  text-align: center;
  letter-spacing: var(--letterSpacing);
  font-weight: bold;
  font-size: 1.25rem;
  margin: 0;
}

.productsCenter {
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  gap: 3rem;
}

.paginateBtnContainer {
  margin: 2rem 0.5rem;
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  gap: 1rem;
}

.pageCount {
  color: var(--grey-500);
  font-size: 0.95rem;
}

.showFilterBtn {
  height: 3.2rem;
  width: 3.2rem;
  font-size: 0.85rem;
  border-radius: 50%;
  font-size: 1.5rem;
  background: var(--black);
  color: var(--white);
  display: flex;
  justify-content: center;
  border-color: transparent;
  cursor: pointer;
  transition: var(--transition);
  position: fixed;
  bottom: 9rem;
  right: 1rem;
  z-index: 5;
}

.showFilterBtn:active {
  transform: scale(0.8);
}

@media screen and (min-width: 768px) {
  .productListSection {
    margin-bottom: 2rem;
  }
  .productListSection .listHeader {
    padding: 0;
  }

  .productListSection > p {
    margin-top: 0;
    text-align: left;
  }

  .productsCenter {
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  }

  .pageCount {
    color: var(--grey-500);
    font-size: 1.15rem;
    letter-spacing: var(--letterSpacing);
  }
}
